import { Button, Flex, UnstyledButton } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { IconExclamationCircle } from "@tabler/icons-react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { ReactComponent as EraLogo } from "../../assets/white-logo.svg";
import { IAuth } from "../../interfaces/IAuth";
import { authChange, authMe } from "../../store/Auth";
import { message } from "../../utils/message";

export const RegisterHeader = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const LogOut = async () => {
    try {
      const removeToken = { accessToken: null };
      const removeUser = {};
      dispatch(authChange(removeToken as any));
      dispatch(authMe(removeUser as any));
      navigate("/login");
    } catch (error: any) {
      message.error(error?.message);
    }
  };

  return (
    <div className={classes.headerWrapper}>
      <header className={classes.header}>
        <Link to={user && user?.id ? "/" : "/login"}>
          <div className={classes.row}>
            <EraLogo className={classes.logo} />
            <div className={classes.subTitle}>payment</div>
          </div>
        </Link>
        <Flex
          gap={12}
          style={{
            zIndex: "10",
          }}>
          <UnstyledButton>
            <IconExclamationCircle color="gray" size={32} />
          </UnstyledButton>
          <Button
            onClick={() => {
              window.location.href = "https://dev-cp.dehub.mn/login";
            }}
            variant="gradient"
            gradient={{ from: "red", to: "#051139" }}
            size="md">
            Нэвтрэх
          </Button>
        </Flex>
      </header>
    </div>
  );
};

export default RegisterHeader;

const useStyles = createStyles((theme) => ({
  headerWrapper: {
    backgroundColor: "#00000090 !important",
  },
  header: {
    padding: "0 20px",
    zIndex: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    justifyItems: "center",
    border: 0,
    alignItems: "center",
    maxWidth: "2000px",
    margin: "auto",
    backgroundColor: "#00000090 !important",
  },
  logo: {
    height: "50px",
  },
  subTitle: {
    display: "flex",
    fontSize: 20,
    fontWeight: 600,
    color: "red",
    marginBottom: "-5px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "end",
    gap: "15px",
    alignItems: "end",
    paddingLeft: "20px",
  },
  user: {
    padding: theme.spacing.xs,
    color: theme.black,
    "&:hover": {
      backgroundColor: "#ffffff10",
    },
  },
  userActive: {
    backgroundColor: theme.white,
  },
}));
